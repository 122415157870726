import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useIntl } from "gatsby-plugin-intl";
// import LanguageSwitch from "./common/LanguageSwitch";
import ScrollTo from "./common/scroll-to";

const HamburgerMenu = ({ show }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="#4e8255"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      viewBox="0 0 20 20"
      className="mx-auto w-10 h-10 p-2 bg-white rounded-md"
    >
      {show ? (
        <>
          <path d="M18 6L6 18" />
          <path d="M6 6L18 18" />
        </>
      ) : (
        <>
          <path d="M3 12L21 12" />
          <path d="M3 6L21 6" />
          <path d="M3 18L21 18" />
        </>
      )}
    </svg>
  );
};

// eslint-disable-next-line react/prop-types
const Nav = ({ background = false }) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);
  const [hasBackground, setHasBackground] = useState(background);

  const toggleBackground = useCallback(() => {
    if (!hasBackground && window.pageYOffset > 50) {
      setHasBackground(true);
    }
  }, [hasBackground]);

  const headerClass = clsx(
    "fixed w-full z-10 top-0",
    {
      "h-full": show,
    },
    `${hasBackground ? "bg-white shadow-lg" : "bg-transparent"} `
  );

  const navClass = clsx(
    "w-full h-full flex flex-col items-center justify-center lg:flex lg:flex-row lg:justify-start bg-white lg:bg-transparent",
    { hidden: !show }
  );

  useEffect(() => {
    toggleBackground();

    window.addEventListener("scroll", debounce(toggleBackground), 50);
  }, [hasBackground, toggleBackground]);

  return (
    <header className={headerClass}>
      <div className="flex flex-col h-full lg:flex-col-reverse pt-1">
        <div className="flex-grow flex flex-col container lg:flex-row lg:items-center h-full">
          <div className="w-full lg:w-2/5 lg:-mr-20 xl:-mr-40">
            <div className="flex flex-row">
              <ScrollTo
                className="flex m-5 text-xl w-3/4 cursor-pointer"
                to="contact"
              >
                <img src="/logofinal5.svg" width="53" height="53" alt="Logo" />{" "}
                <div className="flex flex-col text-xs ml-3">
                  <h3>{intl.formatMessage({ id: "nav.name" })}</h3>
                  <p>{intl.formatMessage({ id: "nav.position" })}</p>
                  <p className="font-bold">
                    {intl.formatMessage({ id: "nav.tel" })}
                  </p>
                </div>
              </ScrollTo>

              <button
                type="button"
                onClick={() => setShow(!show)}
                aria-label="Open menu"
                className="open-menu w-1/4 outline-none lg:hidden"
              >
                <HamburgerMenu show={show} />
              </button>
            </div>
          </div>
          <nav className={navClass}>
            <ScrollTo
              to="services"
              href="#responsive-header"
              activeClassName="text-secondary"
              className="hidden lg:block"
            >
              <h3
                className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold
              lg:inline-block mr-4 uppercase cursor-pointer
              hover:text-secondary"
              >
                {intl.formatMessage({ id: "nav.services" })}
              </h3>
            </ScrollTo>
            <ScrollTo
              to="services"
              href="#responsive-header"
              activeClassName="text-secondary"
              onClick={() => setShow(!show)}
              className="lg:hidden"
            >
              <h3
                className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold
              lg:inline-block mr-4 uppercase cursor-pointer
              hover:text-secondary"
              >
                {intl.formatMessage({ id: "nav.services" })}
              </h3>
            </ScrollTo>

            <ScrollTo
              to="about"
              href="#responsive-header"
              activeClassName="text-secondary"
              className="hidden lg:block"
            >
              <h3
                className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold
              lg:inline-block mr-4 uppercase cursor-pointer
              hover:text-secondary"
              >
                {intl.formatMessage({ id: "nav.about" })}
              </h3>
            </ScrollTo>
            <ScrollTo
              to="about"
              href="#responsive-header"
              activeClassName="text-secondary"
              onClick={() => setShow(!show)}
              className="lg:hidden"
            >
              <h3
                className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold
              lg:inline-block mr-4 uppercase cursor-pointer
              hover:text-secondary"
              >
                {intl.formatMessage({ id: "nav.about" })}
              </h3>
            </ScrollTo>

            <ScrollTo
              to="price"
              href="#responsive-header"
              activeClassName="text-secondary"
              className="hidden lg:block"
            >
              <h3
                className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold
              lg:inline-block mr-4 uppercase cursor-pointer
              hover:text-secondary"
              >
                {intl.formatMessage({ id: "nav.price" })}
              </h3>
            </ScrollTo>
            <ScrollTo
              to="price"
              href="#responsive-header"
              activeClassName="text-secondary"
              onClick={() => setShow(!show)}
              className="lg:hidden"
            >
              <h3
                className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold
              lg:inline-block mr-4 uppercase cursor-pointer
              hover:text-secondary"
              >
                {intl.formatMessage({ id: "nav.price" })}
              </h3>
            </ScrollTo>

            <ScrollTo
              to="contact"
              href="#responsive-header"
              activeClassName="text-secondary"
              className="hidden lg:block"
            >
              <h3
                className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold
              lg:inline-block mr-4 uppercase cursor-pointer
              hover:text-secondary"
              >
                {intl.formatMessage({ id: "nav.contact" })}
              </h3>
            </ScrollTo>
            <ScrollTo
              to="contact"
              href="#responsive-header"
              activeClassName="text-secondary"
              onClick={() => setShow(!show)}
              className="lg:hidden"
            >
              <h3
                className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold
              lg:inline-block mr-4 uppercase cursor-pointer
              hover:text-secondary"
              >
                {intl.formatMessage({ id: "nav.contact" })}
              </h3>
            </ScrollTo>

            {/* <div className="mt-48 mr-5 md:mt-0 md:mr-0 inline">
              <LanguageSwitch />
            </div> */}
          </nav>
        </div>
      </div>
    </header>
  );
};

HamburgerMenu.propTypes = {
  show: PropTypes.bool.isRequired,
};
export default Nav;
