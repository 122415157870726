import React from "react";
import { useIntl, IntlContextConsumer } from "gatsby-plugin-intl";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

const SEO = ({ title, description }) => {
  const intl = useIntl();

  const titleTemplate = title
    ? `${title}`
    : intl.formatMessage({ id: "seo.title" });
  const keywords = intl.formatMessage({ id: "seo.keywords" });
  const defaultDescription = intl.formatMessage({ id: "seo.description" });

  return (
    <IntlContextConsumer>
      {({ language }) => (
        <Helmet
          htmlAttributes={{
            lang: language,
          }}
          title="Fafelmérés"
          titleTemplate={titleTemplate}
          meta={[
            {
              name: `description`,
              content: defaultDescription,
            },
            {
              name: `keywords`,
              content: keywords,
            },
            {
              property: `og:title`,
              content: titleTemplate,
            },
            {
              property: `og:description`,
              content: defaultDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:url`,
              content: "https://fafelmeres.hu",
            },
            {
              property: `og:image`,
              content: `https://fafelmers.com/muszeres.jpg`,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: "author",
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: description,
            },
            {
              name: `google-site-verification`,
              content: "YurTyn8Ox2l0GRBr2wwVe9tJxwddQvWHPRGz1S3PjbI",
            },
          ]}
        />
      )}
    </IntlContextConsumer>
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SEO;
