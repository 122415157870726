import React from "react";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { useIntl } from "gatsby-plugin-intl";
import Img from "gatsby-image";

const About = () => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          about: file(relativePath: { regex: "/about_bg2.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1442) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          me: file(relativePath: { regex: "/me2.jpg/" }) {
            childImageSharp {
              fixed(quality: 100, width: 120) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ about, me }) => (
        <BackgroundImage
          Tag="section"
          id="about"
          fluid={about.childImageSharp.fluid}
        >
          <div className="container md:flex md:flex-row ">
            <div className="md:w-1/2 ">{""}</div>
            <div className="md:w-1/2 md:mb-32 p-5 md:p-10 bg-white md:bg-transparent">
              <h2 className="text-center text-3xl mt-20 mb-10 decor">
                {intl.formatMessage({ id: "about.title" })}
              </h2>
              <div className="mt-10 text-center md:text-left md:flex flex-row justify-center mb-10">
                <Img
                  fixed={me.childImageSharp.fixed}
                  alt="Müller Gergely"
                  className="rounded-lg"
                />
                <div className="my-auto md:ml-5">
                  <h3>{intl.formatMessage({ id: "about.name" })}</h3>
                  <p>{intl.formatMessage({ id: "about.education" })}</p>
                </div>
              </div>
              <p className="max-w-lg m-3 mx-auto">
                {intl.formatMessage({ id: "about.content1" })}{" "}
                <span className="font-bold">
                  {intl.formatMessage({ id: "about.bold1" })}
                </span>{" "}
                {intl.formatMessage({ id: "about.content2" })}
              </p>
              <p className="max-w-lg m-3 mx-auto">
                {intl.formatMessage({ id: "about.content3" })}{" "}
                <span className="font-bold">
                  {intl.formatMessage({ id: "about.bold2" })}
                </span>{" "}
                {intl.formatMessage({ id: "about.content4" })}
              </p>
              <p className="max-w-lg m-3 mx-auto">
                {intl.formatMessage({ id: "about.content5" })}
              </p>
            </div>
          </div>
        </BackgroundImage>
      )}
    />
  );
};

export default About;
