/* eslint-disable react/no-unknown-property */
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { useIntl } from "gatsby-plugin-intl";

const Contact = () => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          contact: file(relativePath: { regex: "/contact_bg2.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1442) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ contact }) => (
        <BackgroundImage
          Tag="section"
          id="contact"
          fluid={contact.childImageSharp.fluid}
        >
          <div className="container md:flex md:flex-row ">
            <div className="md:w-1/2 ">{""}</div>
            <div className="md:w-1/2 md:mb-32 p-5 bg-white md:bg-transparent">
              <h2 className="text-center text-3xl mt-16 decor">
                {intl.formatMessage({ id: "contact.title" })}
              </h2>
              <div className="mx-auto max-w-md md:p-5 lg:p-0 mt-10">
                <p className="max-w-lg m-3 mx-auto">
                  {intl.formatMessage({ id: "contact.phone" })}{" "}
                  <span className="font-bold">
                    {intl.formatMessage({ id: "contact.number" })}
                  </span>
                </p>
                <p className="max-w-lg m-3 mx-auto">
                  {intl.formatMessage({ id: "contact.email" })}{" "}
                  <span className="font-bold">
                    {intl.formatMessage({ id: "contact.addres" })}
                  </span>
                </p>
                <p className="max-w-lg m-3 mx-auto">
                  {intl.formatMessage({ id: "contact.content1" })}
                </p>
                <form
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  action="/success"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <p className="max-w-lg m-3 mx-auto font-bold ">
                    {intl.formatMessage({ id: "contact.message" })}
                  </p>
                  <input
                    id="name"
                    type="text"
                    for="name"
                    name="name"
                    placeholder={intl.formatMessage({ id: "contact.name" })}
                    className="form-control border border-secondary rounded-sm pl-2 pt-1 w-full"
                  />
                  <input
                    id="contacts"
                    type="text"
                    name="contacts"
                    for="contacts"
                    placeholder={intl.formatMessage({ id: "contact.contact" })}
                    className="form-control border pl-2 pt-1 w-full mt-3 border-secondary rounded-sm"
                  />
                  <textarea
                    id="message"
                    type="text"
                    name="message"
                    for="message"
                    placeholder={intl.formatMessage({ id: "contact.messages" })}
                    rows="5"
                    className="form-control border pl-2 pt-1 w-full mt-3 border-secondary rounded-sm"
                  />
                  <button
                    type="submit"
                    className="contact-button uppercase font-bold bg-third hover:bg-secondary text-white px-4 py-2 mt-5 cursor-pointer hover:shadow-xl transition transform duration-200 ease-in-out hover:-translate-y-1 border-2  border-third hover:border-secondary"
                  >
                    {intl.formatMessage({ id: "contact.send" })}{" "}
                    <img
                      src="/icons/paperplane.svg"
                      width="31"
                      height="31"
                      alt="Logo"
                      style={{ display: "inline" }}
                    />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )}
    />
  );
};

export default Contact;
