import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

const When = () => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          when1: file(relativePath: { regex: "/when1.png/" }) {
            childImageSharp {
              fixed(quality: 100, height: 196) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
          when2: file(relativePath: { regex: "/when2.png/" }) {
            childImageSharp {
              fixed(quality: 100, height: 196) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
          when3: file(relativePath: { regex: "/when3.png/" }) {
            childImageSharp {
              fixed(quality: 100, height: 196) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ when1, when2, when3 }) => (
        <section id="when" className="bg-greyy">
          <div className="container mt-20 pt-20 pb-20">
            <h2 className="text-center text-3xl decor">
              {intl.formatMessage({ id: "when.title" })}
            </h2>
            <div className="md:flex md:flex-wrap justify-center mt-20">
              <div className="bg-white rounded-lg md:w-1/3 m-5 p-5 flex flex-col justify-center text-center max-w-sm shadow-xl">
                <Img
                  fixed={when1.childImageSharp.fixed}
                  alt="Szakértői vélemény"
                  className="mx-auto"
                />
                <p className="mt-5 mb-5 md:h-40 lg:h-24">
                  {intl.formatMessage({ id: "when.first.content1" })}{" "}
                  <span className="font-bold">
                    {intl.formatMessage({ id: "when.first.bold" })}
                  </span>{" "}
                  {intl.formatMessage({ id: "when.first.content2" })}
                </p>
              </div>
              <div className="bg-white rounded-lg md:w-1/3 m-5 p-5 flex flex-col justify-center text-center max-w-sm shadow-xl">
                <Img
                  fixed={when2.childImageSharp.fixed}
                  alt="Szakértői vélemény"
                  className="mx-auto"
                />
                <p className="mt-5 mb-5 md:h-40 lg:h-24">
                  {intl.formatMessage({ id: "when.second.content1" })}{" "}
                  <span className="font-bold">
                    {intl.formatMessage({ id: "when.second.bold" })}
                  </span>{" "}
                  {intl.formatMessage({ id: "when.second.content2" })}
                </p>
              </div>
              <div className="bg-white rounded-lg md:w-1/3 m-5 p-5 flex flex-col justify-center text-center max-w-sm shadow-xl">
                <Img
                  fixed={when3.childImageSharp.fixed}
                  alt="Szakértői vélemény"
                  className="mx-auto"
                />
                <p className="mt-5 mb-5 lg:h-24 w-56 mx-auto">
                  {intl.formatMessage({ id: "when.third.content1" })}{" "}
                  <span className="font-bold">
                    {intl.formatMessage({ id: "when.third.bold" })}
                  </span>{" "}
                  {intl.formatMessage({ id: "when.third.content2" })}
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
    />
  );
};

export default When;
