import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useIntl } from "gatsby-plugin-intl";

const Experience = () => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          szakertoi: file(relativePath: { regex: "/szakertoi.jpg/" }) {
            childImageSharp {
              fixed(quality: 100, width: 230) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
          kataszter: file(relativePath: { regex: "/kataszter.jpg/" }) {
            childImageSharp {
              fixed(quality: 100, width: 214) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
          muszer: file(relativePath: { regex: "/muszer.jpg/" }) {
            childImageSharp {
              fixed(quality: 100, width: 216) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ szakertoi, kataszter, muszer }) => (
        <section id="experience" className="">
          <div className="container pt-20 pb-10">
            <h2 className="text-center text-3xl  decor">
              {intl.formatMessage({ id: "experience.title" })}
            </h2>
            <div className="md:flex md:flex-row mt-10">
              <div className="md:w-1/3 lg:m-5 p-5 flex flex-col justify-center text-center">
                <div className="mb-5">
                  <p className="">
                    {intl.formatMessage({ id: "experience.first.content1" })}
                  </p>
                  <h3 className="font-bold text-2xl text-secondary">
                    {intl.formatMessage({ id: "experience.first.bold" })}
                  </h3>
                  <p className="">
                    {intl.formatMessage({ id: "experience.first.content2" })}
                  </p>
                </div>
                <Img
                  fixed={kataszter.childImageSharp.fixed}
                  alt="Kataszter"
                  className="mx-auto"
                />
              </div>
              <div className="md:w-1/3 lg:m-5 p-5 flex flex-col justify-center text-center">
                <div className="mb-5">
                  <p className="">
                    {intl.formatMessage({ id: "experience.second.content1" })}
                  </p>
                  <h3 className="font-bold text-2xl text-secondary">
                    {intl.formatMessage({ id: "experience.second.bold" })}
                  </h3>
                  <p className="">
                    {intl.formatMessage({ id: "experience.second.content2" })}
                  </p>
                </div>
                <Img
                  fixed={muszer.childImageSharp.fixed}
                  alt="Műszeres vizsgálat"
                  className="mx-auto"
                />
              </div>
              <div className="md:w-1/3 lg:m-5 p-5 flex flex-col justify-center text-center">
                <div className="mb-5">
                  <p className="">
                    {intl.formatMessage({ id: "experience.third.content1" })}
                  </p>
                  <h3 className="font-bold text-2xl text-secondary">
                    {intl.formatMessage({ id: "experience.third.bold" })}
                  </h3>
                  <p className="">
                    {intl.formatMessage({ id: "experience.third.content2" })}
                  </p>
                </div>
                <Img
                  fixed={szakertoi.childImageSharp.fixed}
                  alt="Szakértői vélemény"
                  className="mx-auto mb-12"
                />
              </div>
            </div>
          </div>
        </section>
      )}
    />
  );
};

export default Experience;
