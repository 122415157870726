import React from "react";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { useIntl } from "gatsby-plugin-intl";
import ScrollTo from "./common/scroll-to";

const Hero = () => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          hero: file(relativePath: { regex: "/hero_bg2.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1442) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ hero }) => (
        <BackgroundImage
          Tag="section"
          id="hero"
          className="md:mt-24 lg:m-0 md:h-screen"
          fluid={hero.childImageSharp.fluid}
        >
          <div className="container pb-64 pt-32 md:pt-40 lg:pt-48 xl:pt-64 pl-3">
            <div className="md:ml-20 mb-10 md:mb-0">
              <h1 className="text-4xl md:text-5xl xl:text-6xl mb-3">
                {intl.formatMessage({ id: "hero.title" })}
              </h1>
              <div className="w-64 lg:w-full md:max-w-md pr-6 mb-10">
                <p className="font-bold text-lg mb-2">
                  {intl.formatMessage({ id: "hero.services" })}
                </p>
                <p className="leading-relaxed">
                  {intl.formatMessage({ id: "hero.description" })}
                </p>
              </div>
              <ScrollTo
                to="contact"
                type="button"
                aria-label="Scroll to Contact section"
                className="uppercase font-bold bg-third hover:bg-secondary text-white px-4 py-3 mt-5 cursor-pointer hover:shadow-xl transition transform duration-200 ease-in-out hover:scale-110 hover:-translate-y-1 border-2  border-third hover:border-secondary"
              >
                {intl.formatMessage({ id: "hero.button" })}{" "}
                <img
                  src="/icons/paperplane.svg"
                  width="31"
                  height="31"
                  alt="Logo"
                  style={{ display: "inline" }}
                />
              </ScrollTo>
            </div>
          </div>
        </BackgroundImage>
      )}
    />
  );
};

export default Hero;
