import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import {
  SEO,
  Hero,
  Nav,
  Services,
  When,
  About,
  Experience,
  Price,
  Contact,
} from "../components/";

const IndexPage = () => {
  const intl = useIntl();
  return (
    <>
      <SEO
        title={intl.formatMessage({ id: "seo.title" })}
        description={intl.formatMessage({ id: "seo.description" })}
      />
      <Nav />
      <Hero />
      <Services />
      <When />
      <About />
      <Experience />
      <Price />
      <Contact />
    </>
  );
};

export default IndexPage;
